import React from 'react';

const Cube2x2Svg = () => {
    return <div className="px-2">
        <svg width="100%" height="auto" viewBox="0 0 776 771" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="scheme_first-+-1-+-2-+-3" transform="translate(-3656.000000, -401.000000)" strokeWidth="4">
                    <g id="scheme_first" transform="translate(0.000000, 283.000000)" >
                        <g id="8" transform="translate(3475.126030, 118.300000)">
                            <g id="2Group">
                                <g id="Group">
                                    <g id="Group-+-Group-+-Group">
                                        <g id="Group" transform="translate(193.425363, 0.000000)" stroke="#090909" fill="#FFFF00">
                                            <path d="M198.485435,91.823913 L366.065892,169.533895 C372.867928,172.688118 372.888078,177.792779 366.065892,180.956345 L198.485435,258.666328 C191.683399,261.82055 180.675271,261.829894 173.853086,258.666328 L6.27262838,180.956345 C-0.529407026,177.802123 -0.549557342,172.697462 6.27262838,169.533895 L173.853086,91.823913 C180.655121,88.6696905 191.663249,88.6603464 198.485435,91.823913 Z" id="Rectangle-1"></path>
                                            <path d="M365.221376,180.564728 C372.023411,177.410506 383.03154,177.401162 389.853725,180.564728 L557.434183,258.274711 C564.236218,261.428933 564.256368,266.533594 557.434183,269.697161 L389.853725,347.407143 C383.05169,350.561366 372.043562,350.57071 365.221376,347.407143 L197.640919,269.697161 C190.838883,266.542938 190.818733,261.438277 197.640919,258.274711 L365.221376,180.564728 L365.221376,180.564728 Z" id="Rectangle-1"></path>
                                            <path d="M367.153212,2.69148063 C373.955247,-0.462741884 384.963376,-0.472085938 391.785561,2.69148063 L559.366019,80.4014628 C566.168054,83.5556853 566.188204,88.6603464 559.366019,91.823913 L391.785561,169.533895 C384.983526,172.688118 373.975398,172.697462 367.153212,169.533895 L199.572755,91.823913 C192.770719,88.6696905 192.750569,83.5650294 199.572755,80.4014628 L367.153212,2.69148063 L367.153212,2.69148063 Z" id="Rectangle-1"></path>
                                            <path d="M583.153852,91.4322961 L750.734309,169.142278 C757.536344,172.296501 757.556495,177.401162 750.734309,180.564728 L583.153852,258.274711 C576.351816,261.428933 565.343688,261.438277 558.521502,258.274711 L390.941045,180.564728 C384.13901,177.410506 384.118859,172.305845 390.941045,169.142278 L558.521502,91.4322961 C565.323538,88.2780736 576.331666,88.2687295 583.153852,91.4322961 Z" id="Rectangle-1"></path>
                                        </g>
                                        <g id="Group" transform="translate(378.720816, 476.617653) rotate(-302.000000) translate(-378.720816, -476.617653) translate(32.758350, 247.059320)" stroke="#000000" fill="#FF0000">
                                            <path d="M181.017641,118.756191 L334.582697,220.850582 C340.815852,224.99456 340.834317,231.700999 334.582697,235.857253 L181.017641,337.951644 C174.784486,342.095622 164.69701,342.107898 158.44539,337.951644 L4.88033329,235.857253 C-1.35282163,231.713275 -1.3712867,225.006836 4.88033329,220.850582 L158.44539,118.756191 C164.678545,114.612213 174.766021,114.599937 181.017641,118.756191 Z" id="Rectangle-1"></path>
                                            <path d="M335.801577,236.667599 C342.034732,232.523621 352.122208,232.511345 358.373828,236.667599 L511.938885,338.76199 C518.17204,342.905968 518.190505,349.612407 511.938885,353.768661 L358.373828,455.863053 C352.140673,460.00703 342.053197,460.019306 335.801577,455.863053 L182.236521,353.768661 C176.003366,349.624683 175.984901,342.918244 182.236521,338.76199 L335.801577,236.667599 L335.801577,236.667599 Z" id="Rectangle-1"></path>
                                            <path d="M336.250912,3.11258847 C342.484067,-1.03138917 352.571542,-1.04366527 358.823162,3.11258847 L512.388219,105.20698 C518.621374,109.350958 518.639839,116.057397 512.388219,120.213651 L358.823162,222.308042 C352.590007,226.45202 342.502532,226.464296 336.250912,222.308042 L182.685855,120.213651 C176.4527,116.069673 176.434235,109.363234 182.685855,105.20698 L336.250912,3.11258847 L336.250912,3.11258847 Z" id="Rectangle-1"></path>
                                            <path d="M510.753886,118.001597 C516.987041,113.857619 527.074517,113.845343 533.326137,118.001597 L686.891193,220.095988 C693.124348,224.239966 693.142813,230.946405 686.891193,235.102659 L533.326137,337.197051 C527.092982,341.341028 517.005506,341.353304 510.753886,337.197051 L357.188829,235.102659 C350.955674,230.958681 350.937209,224.252242 357.188829,220.095988 L510.753886,118.001597 L510.753886,118.001597 Z" id="Rectangle-1"></path>
                                        </g>
                                        <g id="Group" transform="translate(762.348631, 473.450851) scale(-1, 1) rotate(-303.000000) translate(-762.348631, -473.450851) translate(408.635479, 250.934184)" stroke="#090909" fill="#3CFD03">
                                            <path d="M185.370786,114.876672 L342.007143,213.635852 C348.364961,217.644454 348.383796,224.131808 342.007143,228.152285 L185.370786,326.911465 C179.012968,330.920068 168.723742,330.931943 162.34709,326.911465 L5.71073215,228.152285 C-0.647085875,224.143683 -0.665920243,217.656329 5.71073215,213.635852 L162.34709,114.876672 C168.704908,110.86807 178.994133,110.856195 185.370786,114.876672 Z" id="Rectangle-1"></path>
                                            <path d="M343.250401,228.936159 C349.608219,224.927557 359.897444,224.915682 366.274097,228.936159 L522.910454,327.695339 C529.268273,331.703941 529.287107,338.191295 522.910454,342.211772 L366.274097,440.970952 C359.916279,444.979555 349.627053,444.99143 343.250401,440.970952 L186.614043,342.211772 C180.256225,338.20317 180.237391,331.715816 186.614043,327.695339 L343.250401,228.936159 L343.250401,228.936159 Z" id="Rectangle-1"></path>
                                            <path d="M366.732418,3.01090666 L523.368776,101.770086 C529.726594,105.778689 529.745428,112.266042 523.368776,116.28652 L366.732418,215.0457 C360.3746,219.054302 350.085374,219.066177 343.708722,215.0457 L187.072364,116.28652 C180.714546,112.277918 180.695712,105.790564 187.072364,101.770086 L343.708722,3.01090666 C350.06654,-0.997695825 360.355765,-1.00957089 366.732418,3.01090666 Z" id="Rectangle-1"></path>
                                            <path d="M544.725451,114.146729 L701.361809,212.905909 C707.719627,216.914511 707.738462,223.401865 701.361809,227.422343 L544.725451,326.181522 C538.367633,330.190125 528.078408,330.202 521.701756,326.181522 L365.065398,227.422343 C358.70758,223.41374 358.688746,216.926387 365.065398,212.905909 L521.701756,114.146729 C528.059574,110.138127 538.348799,110.126252 544.725451,114.146729 Z" id="Rectangle-1"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
}
export default Cube2x2Svg;