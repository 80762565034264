import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
// import { Container } from 'react-bootstrap';
import LayoutTitle from '../Layouts/LayoutTitle/LayoutTitle'
import containerStyles from './lessonBoxes.module.css'
import ModalWindow from '../modalWindow'
import Cube3x3Svg  from './Cube3x3Svg'
import Cube2x2Svg  from './Cube2x2Svg'
import Pyramid from './Pyramid'
import RobotsHomeSvg from '../HomePage/RobotsHomePageSvg'
// import goodsImage from '../../images/goods_amazon.jpg'

const LessonBoxes= () => {
  const { t } = useTranslation();
    return <div className="mb-5">
      <LayoutTitle>
        <h1>{t('homepage.h1_home_page')}</h1>
      </LayoutTitle>
      <ModalWindow/>
      <div className="container d-flex flex-column justify-content-center align-items-center mt-5">
        <p className="fs-4 fw-bold">{t('homepage.p1')}</p>
        <p className="fs-4 text-center mb-5">{t('homepage.p2')}</p>
        <RobotsHomeSvg/>
        
      </div>
      <div className="container mt-5 mb-4">
        <div className="row g-2 d-flex align-items-center justify-content-center">
          <div className="col-6 col-md-4" >
            <Link to="/home3x3/">
              <div className={containerStyles.lessons_item}>
                <p className="fs-2 text-black pt-2">3x3</p>
                <Cube3x3Svg className="p-0 m-0"/>
                <div className="border-top w-100 p-3">
                  <p className="text-black m-0">{t('homepage.3x3level')} <span className="fw-bold">{t('homepage.3x3level_value')}</span></p>
                  <p className="text-black m-0">{t('homepage.3x3steps')}</p>
                  <p className="text-black m-0">{t('homepage.3x3time')}</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-4" >
            <Link to="/page-cube2x2/">
              <div className={containerStyles.lessons_item}>
                <p className="fs-2 text-black pt-2">2x2</p>
                <Cube2x2Svg />
                <div className="border-top w-100 p-3">
                  <p className="text-black m-0">{t('homepage.2x2level')} <span className="fw-bold">{t('homepage.2x2level_value')}</span></p>
                  <p className="text-black m-0">{t('homepage.2x2steps')}</p>
                  <p className="text-black m-0">{t('homepage.2x2time')}</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12"></div>
          {/* Pyramid */}
          <div className="col-6 col-md-4 mt-4 h-100" >
            <Link to="/page-pyramid/">
              <div className={containerStyles.lessons_item} >
                <p className="fs-2 text-black pt-2">{t('nav.pyramid')}</p>
                <Pyramid />
                <div className="border-top w-100 p-3">
                  <p className="text-black m-0">{t('homepage.2x2level')} <span className="fw-bold">{t('homepage.2x2level_value')}</span></p>
                  <p className="text-black m-0">{t('homepage.2x2steps')}</p>
                  <p className="text-black m-0">{t('homepage.2x2time')}</p>
                </div>
              </div>
            </Link>
          </div>

          {/* goods Amazon */}
          {/* <div className="col-6 col-md-4 mt-4" >
            <Link to="/goods_amazon/">
              <div className={containerStyles.lessons_item} >
                <p className="fs-2 text-black pt-2">{t('homepage.goods_title')}</p>
                <img src={goodsImage} width="100%" alt="Goods Rubik's cube style | Товары с символикой Кубика Рубика" />
                <div className="border-top w-100 p-3">
                  <p className="text-black m-0">{t('homepage.goods_description')}</p>
                </div>
              </div>
            </Link>
          </div> */}

          {/* <div className="col-12"></div> */}
          
          {/* goods Amazon */}
          {/* <div className="col-12 col-md-4 mt-4" >
            <Link to="/goods_amazon/">
              <div className={containerStyles.lessons_item} >
                <p className="fs-2 text-black pt-2">{t('homepage.goods_title')}</p>
                <img src={goodsImage} width="100%" alt="Goods Rubik's cube style | Товары с символикой Кубика Рубика" />
                <div className="border-top w-100 p-3">
                  <p className="text-black m-0">{t('homepage.goods_description')}</p>
                </div>
              </div>
            </Link>
          </div> */}
        </div>
      </div>
    </div> 
}
export default LessonBoxes