import React from 'react';

const Cube3x3Svg = () => {
    return <div className="px-2">
        <svg width="100%" height="auto" viewBox="0 0 830 822" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
                <g id="RUS" transform="translate(-8082.000000, -8437.000000)" strokeWidth="4">
                    <g id="Group" transform="translate(4327.000000, 0.000000)">
                        <g id="8" transform="translate(576.000000, 8437.000000)">
                            <g id="этапы-+-этапы-+-Group">
                                <g id="этапы-+-этапы" transform="translate(2984.000000, 0.000000)">
                                    <g id="2Group">
                                        <g id="Group">
                                            <g id="Group-+-Group-+-Group">
                                                <g id="Group" transform="translate(208.257453, 0.000000)" stroke="#090909" fill="#FFFF00">
                                                    <path d="M123.200097,127.553092 C128.02692,125.313408 135.838448,125.306773 140.67957,127.553092 L259.597102,182.731778 C264.423925,184.971462 264.438224,188.596074 259.597102,190.842393 L140.67957,246.021079 C135.852747,248.260763 128.041219,248.267398 123.200097,246.021079 L4.28256586,190.842393 C-0.544257445,188.602709 -0.558556403,184.978097 4.28256586,182.731778 L123.200097,127.553092 L123.200097,127.553092 Z" id="Rectangle-1"></path>
                                                    <path d="M277.848153,191.200412 L396.765684,246.379097 C401.592507,248.618782 401.606806,252.243393 396.765684,254.489713 L277.848153,309.668398 C273.02133,311.908083 265.209802,311.914718 260.368679,309.668398 L141.451148,254.489713 C136.624325,252.250028 136.610026,248.625417 141.451148,246.379097 L260.368679,191.200412 C265.195503,188.960727 273.007031,188.954092 277.848153,191.200412 Z" id="Rectangle-1"></path>
                                                    <path d="M396.166403,254.211641 C400.993226,251.971957 408.804754,251.965322 413.645876,254.211641 L532.563408,309.390327 C537.390231,311.630012 537.40453,315.254623 532.563408,317.500943 L413.645876,372.679628 C408.819053,374.919313 401.007525,374.925948 396.166403,372.679628 L277.248872,317.500943 C272.422049,315.261258 272.40775,311.636647 277.248872,309.390327 L396.166403,254.211641 L396.166403,254.211641 Z" id="Rectangle-1"></path>
                                                    <path d="M260.196382,64.1838439 C265.023206,61.9441593 272.834734,61.9375244 277.675856,64.1838439 L396.593387,119.362529 C401.42021,121.602214 401.434509,125.226826 396.593387,127.473145 L277.675856,182.651831 C272.849033,184.891515 265.037505,184.89815 260.196382,182.651831 L141.278851,127.473145 C136.452028,125.23346 136.437729,121.608849 141.278851,119.362529 L260.196382,64.1838439 L260.196382,64.1838439 Z" id="Rectangle-1"></path>
                                                    <path d="M397.537262,127.911111 C402.364085,125.671426 410.175613,125.664791 415.016735,127.911111 L533.934267,183.089796 C538.76109,185.329481 538.775389,188.954092 533.934267,191.200412 L415.016735,246.379097 C410.189912,248.618782 402.378384,248.625417 397.537262,246.379097 L278.619731,191.200412 C273.792908,188.960727 273.778609,185.336116 278.619731,183.089796 L397.537262,127.911111 L397.537262,127.911111 Z" id="Rectangle-1"></path>
                                                    <path d="M550.814459,190.92234 L669.73199,246.101026 C674.558813,248.340711 674.573112,251.965322 669.73199,254.211641 L550.814459,309.390327 C545.987636,311.630012 538.176108,311.636647 533.334986,309.390327 L414.417454,254.211641 C409.590631,251.971957 409.576332,248.347345 414.417454,246.101026 L533.334986,190.92234 C538.161809,188.682656 545.973337,188.676021 550.814459,190.92234 Z" id="Rectangle-1"></path>
                                                    <path d="M397.885217,1.68225245 C402.71204,-0.557432174 410.523568,-0.564067005 415.36469,1.68225245 L534.282222,56.860938 C539.109045,59.1006227 539.123344,62.7252341 534.282222,64.9715535 L415.36469,120.150239 C410.537867,122.389924 402.726339,122.396559 397.885217,120.150239 L278.967686,64.9715535 C274.140863,62.7318689 274.126564,59.1072575 278.967686,56.860938 L397.885217,1.68225245 L397.885217,1.68225245 Z" id="Rectangle-1"></path>
                                                    <path d="M533.016629,63.7760106 C537.843452,61.536326 545.65498,61.5296912 550.496102,63.7760106 L669.413633,118.954696 C674.240456,121.194381 674.254755,124.818992 669.413633,127.065312 L550.496102,182.243997 C545.669279,184.483682 537.857751,184.490317 533.016629,182.243997 L414.099097,127.065312 C409.272274,124.825627 409.257975,121.201016 414.099097,118.954696 L533.016629,63.7760106 L533.016629,63.7760106 Z" id="Rectangle-1"></path>
                                                    <path d="M668.814352,126.787241 C673.641175,124.547556 681.452703,124.540921 686.293826,126.787241 L805.211357,181.965926 C810.03818,184.205611 810.052479,187.830222 805.211357,190.076542 L686.293826,245.255227 C681.467002,247.494912 673.655474,247.501547 668.814352,245.255227 L549.896821,190.076542 C545.069998,187.836857 545.055699,184.212246 549.896821,181.965926 L668.814352,126.787241 L668.814352,126.787241 Z" id="Rectangle-1"></path>
                                                </g>
                                                <g id="Group" transform="translate(406.092850, 507.986011) rotate(-302.000000) translate(-406.092850, -507.986011) translate(35.092850, 261.986011)" stroke="#000000" fill="#FF0000">
                                                    <path d="M112.896397,167.577639 C117.319535,164.63517 124.477754,164.626453 128.913995,167.577639 L237.885994,240.070698 C242.309131,243.013168 242.322234,247.775136 237.885994,250.726322 L128.913995,323.219382 C124.490857,326.161851 117.332638,326.170568 112.896397,323.219382 L3.92439834,250.726322 C-0.498739094,247.783853 -0.511842174,243.021884 3.92439834,240.070698 L112.896397,167.577639 L112.896397,167.577639 Z" id="Rectangle-1"></path>
                                                    <path d="M238.593041,251.196682 C243.016179,248.254213 250.174399,248.245496 254.610639,251.196682 L363.582638,323.689742 C368.005775,326.632211 368.018878,331.394179 363.582638,334.345366 L254.610639,406.838425 C250.187502,409.780894 243.029282,409.789611 238.593041,406.838425 L129.621043,334.345366 C125.197905,331.402896 125.184802,326.640928 129.621043,323.689742 L238.593041,251.196682 L238.593041,251.196682 Z" id="Rectangle-1"></path>
                                                    <path d="M379.051075,333.980039 L488.023073,406.473098 C492.446211,409.415568 492.459314,414.177536 488.023073,417.128722 L379.051075,489.621782 C374.627937,492.564251 367.469718,492.572968 363.033477,489.621782 L254.061478,417.128722 C249.638341,414.186253 249.625238,409.424284 254.061478,406.473098 L363.033477,333.980039 C367.456614,331.03757 374.614834,331.028853 379.051075,333.980039 Z" id="Rectangle-1"></path>
                                                    <path d="M254.452752,84.3239223 L363.424751,156.816981 C367.847888,159.759451 367.860991,164.521419 363.424751,167.472606 L254.452752,239.965665 C250.029614,242.908134 242.871395,242.916851 238.435154,239.965665 L129.463156,167.472606 C125.040018,164.530136 125.026915,159.768168 129.463156,156.816981 L238.435154,84.3239223 C242.858292,81.3814529 250.016511,81.3727362 254.452752,84.3239223 Z" id="Rectangle-1"></path>
                                                    <path d="M364.289686,168.047999 C368.712823,165.10553 375.871043,165.096813 380.307283,168.047999 L489.279282,240.541058 C493.70242,243.483528 493.715523,248.245496 489.279282,251.196682 L380.307283,323.689742 C375.884146,326.632211 368.725926,326.640928 364.289686,323.689742 L255.317687,251.196682 C250.89455,248.254213 250.881446,243.492244 255.317687,240.541058 L364.289686,168.047999 L364.289686,168.047999 Z" id="Rectangle-1"></path>
                                                    <path d="M488.730121,250.831356 C493.153259,247.888887 500.311478,247.88017 504.747719,250.831356 L613.719718,323.324415 C618.142855,326.266884 618.155958,331.028853 613.719718,333.980039 L504.747719,406.473098 C500.324581,409.415568 493.166362,409.424284 488.730121,406.473098 L379.758123,333.980039 C375.334985,331.03757 375.321882,326.275601 379.758123,323.324415 L488.730121,250.831356 L488.730121,250.831356 Z" id="Rectangle-1"></path>
                                                    <path d="M364.60854,2.21012199 C369.031677,-0.73234734 376.189897,-0.741064096 380.626138,2.21012199 L489.598136,74.7031812 C494.021274,77.6456505 494.034377,82.4076191 489.598136,85.3588052 L380.626138,157.851864 C376.203,160.794334 369.04478,160.803051 364.60854,157.851864 L255.636541,85.3588052 C251.213404,82.4163359 251.200301,77.6543673 255.636541,74.7031812 L364.60854,2.21012199 L364.60854,2.21012199 Z" id="Rectangle-1"></path>
                                                    <path d="M488.43839,83.7881161 C492.861527,80.8456467 500.019747,80.83693 504.455987,83.7881161 L613.427986,156.281175 C617.851124,159.223645 617.864227,163.985613 613.427986,166.936799 L504.455987,239.429858 C500.03285,242.372328 492.87463,242.381045 488.43839,239.429858 L379.466391,166.936799 C375.043254,163.99433 375.03015,159.232361 379.466391,156.281175 L488.43839,83.7881161 L488.43839,83.7881161 Z" id="Rectangle-1"></path>
                                                    <path d="M612.878825,166.571473 C617.301963,163.629004 624.460182,163.620287 628.896423,166.571473 L737.868422,239.064532 C742.291559,242.007001 742.304662,246.76897 737.868422,249.720156 L628.896423,322.213215 C624.473285,325.155685 617.315066,325.164401 612.878825,322.213215 L503.906827,249.720156 C499.483689,246.777687 499.470586,242.015718 503.906827,239.064532 L612.878825,166.571473 L612.878825,166.571473 Z" id="Rectangle-1"></path>
                                                </g>
                                                <g id="Group" transform="translate(816.339647, 505.329906) scale(-1, 1) rotate(-303.000000) translate(-816.339647, -505.329906) translate(437.839647, 267.329906)" stroke="#090909" fill="#3CFD03">
                                                    <path d="M115.154325,162.103225 C119.665925,159.25688 126.967309,159.248448 131.492275,162.103225 L242.643713,232.228086 C247.155314,235.074431 247.168679,239.680836 242.643713,242.535613 L131.492275,312.660475 C126.980674,315.50682 119.67929,315.515252 115.154325,312.660475 L4.00288631,242.535613 C-0.508713875,239.689268 -0.522079017,235.082863 4.00288631,232.228086 L115.154325,162.103225 L115.154325,162.103225 Z" id="Rectangle-1"></path>
                                                    <path d="M243.364902,242.990608 C247.876502,240.144263 255.177886,240.135831 259.702852,242.990608 L370.854291,313.115469 C375.365891,315.961814 375.379256,320.568219 370.854291,323.422996 L259.702852,393.547857 C255.191252,396.394202 247.889868,396.402634 243.364902,393.547857 L132.213464,323.422996 C127.701863,320.576651 127.688498,315.970246 132.213464,313.115469 L243.364902,242.990608 L243.364902,242.990608 Z" id="Rectangle-1"></path>
                                                    <path d="M370.294147,323.069604 C374.805747,320.223259 382.107131,320.214827 386.632096,323.069604 L497.783535,393.194465 C502.295135,396.04081 502.3085,400.647215 497.783535,403.501992 L386.632096,473.626853 C382.120496,476.473198 374.819112,476.48163 370.294147,473.626853 L259.142708,403.501992 C254.631108,400.655647 254.617742,396.049242 259.142708,393.194465 L370.294147,323.069604 L370.294147,323.069604 Z" id="Rectangle-1"></path>
                                                    <path d="M259.541807,81.5692346 L370.693246,151.694096 C375.204846,154.540441 375.218211,159.146846 370.693246,162.001623 L259.541807,232.126484 C255.030207,234.972829 247.728823,234.981261 243.203857,232.126484 L132.052419,162.001623 C127.540818,159.155278 127.527453,154.548873 132.052419,151.694096 L243.203857,81.5692346 C247.715458,78.7228896 255.016842,78.7144576 259.541807,81.5692346 Z" id="Rectangle-1"></path>
                                                    <path d="M371.575479,162.558219 C376.08708,159.711874 383.388464,159.703442 387.913429,162.558219 L499.064868,232.683081 C503.576468,235.529426 503.589833,240.135831 499.064868,242.990608 L387.913429,313.115469 C383.401829,315.961814 376.100445,315.970246 371.575479,313.115469 L260.424041,242.990608 C255.912441,240.144263 255.899075,235.537858 260.424041,232.683081 L371.575479,162.558219 L371.575479,162.558219 Z" id="Rectangle-1"></path>
                                                    <path d="M498.504724,242.637216 C503.016324,239.790871 510.317708,239.782439 514.842673,242.637216 L625.994112,312.762077 C630.505712,315.608422 630.519077,320.214827 625.994112,323.069604 L514.842673,393.194465 C510.331073,396.04081 503.029689,396.049242 498.504724,393.194465 L387.353285,323.069604 C382.841685,320.223259 382.82832,315.616854 387.353285,312.762077 L498.504724,242.637216 L498.504724,242.637216 Z" id="Rectangle-1"></path>
                                                    <path d="M388.23866,2.13792189 L499.390099,72.2627832 C503.901699,75.1091281 503.915064,79.7155331 499.390099,82.5703101 L388.23866,152.695171 C383.72706,155.541516 376.425676,155.549948 371.900711,152.695171 L260.749272,82.5703101 C256.237672,79.7239651 256.224307,75.1175601 260.749272,72.2627832 L371.900711,2.13792189 C376.412311,-0.708423071 383.713695,-0.716855069 388.23866,2.13792189 Z" id="Rectangle-1"></path>
                                                    <path d="M514.545107,81.0509321 L625.696546,151.175793 C630.208146,154.022138 630.221511,158.628543 625.696546,161.48332 L514.545107,231.608182 C510.033507,234.454527 504.208763,234.909333 498.207158,231.608182 C440.464069,199.846902 387.055719,161.48332 387.055719,161.48332 C382.544119,158.636975 382.530753,154.03057 387.055719,151.175793 L498.207158,81.0509321 C502.718758,78.2045871 510.020142,78.1961551 514.545107,81.0509321 Z" id="Rectangle-1"></path>
                                                    <path d="M625.136402,161.129928 C629.648002,158.283583 636.949386,158.275151 641.474351,161.129928 L752.62579,231.25479 C757.13739,234.101135 757.150755,238.70754 752.62579,241.562317 L641.474351,311.687178 C636.962751,314.533523 629.661367,314.541955 625.136402,311.687178 L513.984963,241.562317 C509.473363,238.715972 509.459998,234.109567 513.984963,231.25479 L625.136402,161.129928 L625.136402,161.129928 Z" id="Rectangle-1"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
}
export default Cube3x3Svg;