import React from 'react';

const Pyramid = () => {
    return <div className="px-2">
        <svg width="100%" height="auto" viewBox="0 0 1060 814" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-+-arrow-+-1" transform="translate(-3228.000000, -366.000000)" stroke="#090909" strokeWidth="4">
                    <g id="Group" transform="translate(0.000000, 352.174938)">
                        <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(3228.737474, 13.458915)">
                            <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 0.616398)">
                                <path d="M531.795617,276.790898 L352.66494,208.029779 L535.17685,0.278728367 L531.795617,276.790898 Z" id="Triangle-39" fill="#FFFF00"></path>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 205.260511)" fill="#FFFF00">
                                    <path d="M356.465252,68.5474174 L354.718676,340.801205 L175.587998,272.040086 L356.465252,68.5474174 L356.465252,68.5474174 Z" id="Triangle-39"></path>
                                    <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39"></path>
                                    <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39"></path>
                                </g>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 405.778778)">
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 66.139498)">
                                        <path d="M355.324904,68.7283168 L352.513721,340.573444 L175.512257,272.629645 L355.324904,68.7283168 L355.324904,68.7283168 Z" id="Triangle-39" fill="#FFFF00"></path>
                                        <path d="M178.823782,0.666388525 L174.947993,272.102856 L0.07574142,204.976377 L178.823782,0.666388525 L178.823782,0.666388525 Z" id="Triangle-39" fill="#3CFD03"></path>
                                        <path d="M175.065854,274.672132 L354.878501,70.7708033 L177.877037,2.82700464 L175.065854,274.672132 L175.065854,274.672132 Z" id="Triangle-39" fill="#FFFF00"></path>
                                    </g>
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" fill="#FFFF00">
                                        <path d="M355.324904,68.7283168 L352.513721,340.573444 L175.512257,272.629645 L355.324904,68.7283168 L355.324904,68.7283168 Z" id="Triangle-39"></path>
                                        <path d="M178.823782,0.666388525 L174.947993,272.102856 L0.07574142,204.976377 L178.823782,0.666388525 L178.823782,0.666388525 Z" id="Triangle-39"></path>
                                        <path d="M175.065854,274.672132 L354.878501,70.7708033 L177.877037,2.82700464 L175.065854,274.672132 L175.065854,274.672132 Z" id="Triangle-39"></path>
                                    </g>
                                </g>
                            </g>
                            <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(794.368664, 412.520037) scale(-1, 1) rotate(-1.000000) translate(-794.368664, -412.520037) translate(526.242098, 5.948652)" fill="#3CFD03">
                                <path d="M531.795617,276.790898 L352.66494,208.029779 L535.17685,0.278728367 L531.795617,276.790898 Z" id="Triangle-39"></path>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 205.260511)">
                                    <path d="M356.465252,68.5474174 L354.718676,340.801205 L175.587998,272.040086 L356.465252,68.5474174 L356.465252,68.5474174 Z" id="Triangle-39"></path>
                                    <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39"></path>
                                    <path d="M354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L354.878501,70.589904 Z" id="Triangle-39"></path>
                                </g>
                                <g id="Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(0.000000, 405.959677)">
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39" transform="translate(175.613574, 66.139498)">
                                        <path d="M355.324904,68.5474174 L352.513721,340.392545 L175.512257,272.448746 L355.324904,68.5474174 L355.324904,68.5474174 Z" id="Triangle-39"></path>
                                        <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39"></path>
                                        <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39"></path>
                                    </g>
                                    <g id="Triangle-39-+-Triangle-39-+-Triangle-39">
                                        <path d="M355.324904,68.5474174 L352.513721,340.392545 L175.512257,272.448746 L355.324904,68.5474174 L355.324904,68.5474174 Z" id="Triangle-39"></path>
                                        <path d="M178.823782,0.485489132 L174.947993,271.921956 L0.07574142,204.795478 L178.823782,0.485489132 L178.823782,0.485489132 Z" id="Triangle-39"></path>
                                        <path d="M175.065854,274.491232 L354.878501,70.589904 L177.877037,2.64610525 L175.065854,274.491232 L175.065854,274.491232 Z" id="Triangle-39"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
}
export default Pyramid;