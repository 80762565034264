import React from "react"
// import { Link } from "gatsby"
// import { Container, Button } from 'react-bootstrap';
import SEO from "../components/seo"
// import { useTranslation } from "react-i18next"
// import Home from '../components/Home/Home'
import LessonBoxes from '../components/LessonBoxes/LessonBoxes'
const IndexPage = (props) => {
  // const { t } = useTranslation()

  return (
    <div>
      <SEO title="How to solve a Rubik's cube   Как собрать кубик Рубика" />
      <LessonBoxes />
    </div>
  )

  // return (
  //   <div>
  //     <SEO title="Home" />
  //     <h1>{t('home.title')}</h1>
  //     {t('home.content').split('\n').map(function(item, key) {
  //         return (
  //           <p key={key}>
  //             {item}
  //           </p>
  //         )
  //       })
  //     }
  //     <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
  //       <Image />
  //     </div>
  //     <Link to="/page-2/">{t('home.link')}</Link>
  //   </div>
  // )
}

export default IndexPage
