import React from 'react';
import LanguageMenu from '../components/languageMenu'
// import { Link } from "gatsby"

// import Layout from "../components/layout"


// import { useTranslation } from "react-i18next"
// import { Container } from 'react-bootstrap';
import { Modal} from "react-bootstrap"


class ModalWindow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        };
        
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }
    
    handleClose (){
        this.setState({ show: false });
    };
    
    handleShow (){
        this.setState({ show: true });
    };
    
    render() {
        // const { t } = useTranslation()
        return(
            <div>
                {/* <Button variant="primary" onClick={this.handleShow}>Launch modal</Button> */}

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ textAlign: 'center'}}>Выбор языка | Сhoose language</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center'}}>
                        <LanguageMenu />
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                        <Button variant="primary">Save Changes</Button>
                    </Modal.Footer> */}
                </Modal>
            </div>
        );
    }
}

export default ModalWindow;